.quill {
    /*border: 1px solid red;*/
}

.ql-snow {
    font-family: Gilroy, sans-serif !important;
    /*padding: 0 8px !important;*/
    display: flex !important;
    position: relative !important;
    border: 0.6px solid rgba(12, 18, 21, 0.2)!important;
    border-radius: 5px !important;
    margin-bottom: 8px !important;
}
.ql-blank {
    min-height: 200px;
    max-width: 100%;
    height: auto;
    line-height: 1.5714285714285714;
    vertical-align: bottom;
    transition: all 0.3s, height 0s;
    resize: vertical;
    font-size: 16px;
}

.ql-editor {
    width: 100%;
    min-height: 200px;
    max-width: 100%;
    height: auto;
    line-height: 1.5714285714285714;
    vertical-align: bottom;
    transition: all 0.3s, height 0s;
    resize: vertical;
    font-size: 16px;
    box-sizing: border-box;
    outline: none;
    overflow-y: auto;
    padding: 12px 15px;
    tab-size: 4;
    -moz-tab-size: 4;
    text-align: left;
    white-space: pre-wrap;
    word-wrap: break-word;
}

.ql-formats {
    margin-right: 15px;
    display: inline-block;
    vertical-align: middle;
}

/*.ql-editor {*/
/*    box-sizing: border-box;*/
/*    line-height: 1.42;*/
/*    height: 100%;*/
/*    outline: none;*/
/*    overflow-y: auto;*/
/*    padding: 12px 15px;*/
/*    tab-size: 4;*/
/*    -moz-tab-size: 4;*/
/*    text-align: left;*/
/*    white-space: pre-wrap;*/
/*    word-wrap: break-word;*/
/*}*/

.ql-toolbar button {
    padding: 0 5px !important;
}

.ql-picker-label {
    border:none !important;
    padding: 0 5px !important;
    font-family: Gilroy, sans-serif !important;
    font-weight: 500;
    font-size: 15px;
    line-height: 26.4px;
    color: rgb(12, 18, 21);

}
.ql-clean {
    padding: 0 5px !important;
}

