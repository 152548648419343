@font-face {
  font-family: 'Cormorant Garamond';
  src: url(../src/assets/fonts/Cormorant/CormorantGaramond-SemiBold.woff2),
  url(../src/assets/fonts/Cormorant/CormorantGaramond-SemiBold.woff2);
  font-style: normal;
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: url(../src/assets/fonts/Gilroy/Gilroy-Regular.woff2),
  url(../src/assets/fonts/Gilroy/Gilroy-Regular.woff);
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: url(../src/assets/fonts/Gilroy/Gilroy-Medium.woff2),
  url(../src/assets/fonts/Gilroy/Gilroy-Medium.woff);
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: url(../src/assets/fonts/Gilroy/Gilroy-SemiBold.woff2),
  url(../src/assets/fonts/Gilroy/Gilroy-SemiBold.woff);
  font-style: normal;
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url(../src/assets/fonts/Roboto/Roboto-Medium.woff2),
  url(../src/assets/fonts/Roboto/Roboto-Medium.woff);
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}


html {
  width: 100vw;
  overflow-x: hidden !important;
  scroll-behavior: smooth;
}

* {
  padding: 0;
  margin: 0;
  border: 0;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

:focus,
:active {
  outline: none;
}

html,
body {
  height: 100%;
  font-family: 'Gilroy', sans-serif;
}

body {
  line-height: 1;
  /*overflow-y: auto;*/
}

input,
button,
textarea {
  font-size: inherit;
}

button {
  cursor: pointer;
  color: inherit;
  background-color: inherit;
}

a {
  color: inherit;
  text-decoration-line: none !important;
  transition: all 0.3s;
}

a:link,
a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

ul li {
  list-style: none;
}

img {
  vertical-align: top;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: inherit;
  font-size: inherit;
}

body {
  height: 100%;
  font-family: 'Gilroy', serif;
  font-weight: 300;
  font-size: 18px;
  line-height: 1.4em;
  color: var(--black);
  min-width: 320px;
  background: #fff;
}

:root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: auto;
}
