:root {
    --black: #0C1215;
    --white: #ffffff;
    --blueButton:#1056B2;
    --blueButtonHover: #0C4F9B;

    /*--bg: #F8E367;*/
    /*--shadow1: 0px 13px 48px -1px rgba(0, 0, 0, 0.25);*/
    /*--shadow2: 0px 13px 46px rgba(0, 0, 0, 0.13);*/
    /*--pink: #FE956F;*/
}